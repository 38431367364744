import { observer } from "mobx-react";
import React, { Fragment, useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router";
import {
  Divider,
  Card,
  Segment,
  Dimmer,
  Loader,
  LabelGroup,
  Label,
  Icon,
  Grid,
  Sticky,
  Ref,
  Button,
  SidebarPushable,
  Sidebar,
  SidebarPusher,
  Popup,
  List
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import CatalogueCard from "./components/CatalogueCard";
import { MainBanner } from "../mainBanner/MainBanner";
import { NoResults } from "../../common/NoResults";
import CategoryBar from "./components/CategoryBar";
import { Brand, SortByOptions } from "../../interfaces/search";
import { requestUserSession } from "../registration/functions/sessionFnc";
import { AdvertPanel } from "./components/AdvertPanel";
import { IAdvertFilter } from "../../interfaces/advert";

const ShoppingCatalogue = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let email = searchParams.get('email');
  let brand = searchParams.get('brand');
  let query = searchParams.get('q');
  let category = searchParams.get('c');

  if (email || brand) {
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
  }

  const stickyRef = React.createRef<HTMLElement>();
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const {
    dispose,
    load,
    products,
    loading,
    setSidebarOpen,
    sidebarOpen,
    search,
    selectedBrands,
    selectedCategories,
    searchText,
    removeBrand,
    removeCategory,
    selectedBrands_obj,
    hasMore,
    sortBy,
    setSortBy,
    suggestions,
    suggestion_search,
    available_brands,
    setBrandsIds,
    setBrands,
    setExpandedCategories,
    setLastClickedId,
    setSelectedCategories
  } = context.shoppingCatalogueStore;
  const { setPage } = context.analyticsStore;
  const { openModal, closeModal } = context.modalStore;
  const { smartCategories, brands } = context.cacheStore;
  const { isMobile, isTablet } = context.deviceStore;
  const { state, setDrawerState, dispose_search } = context.searchStore;
  const headerOffset = (document.querySelector('header')?.offsetHeight || 70); // Added search tag offset

  if (email) {
    requestUserSession(
      isMobile,
      openModal,
      closeModal,
      () => {
        closeModal();
      },
      email
    );
  }

  const scroll = () => {
    const targetDiv = document.getElementsByClassName('productsContainer');
    if (!targetDiv || targetDiv.length <= 0) {
      return;
    }
    if (state.isDrawerOpen === true) {
      setDrawerState(false);
    }
    const y = targetDiv[0].getBoundingClientRect().top + window.scrollY - headerOffset + 5 //- (document.querySelector('.ui .large .label .ct-green-button-active') ? 41 : 0);
    window.scrollTo({
      top:
        y,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (brand) {
      setBrands(brands.filter(f => f.id === brand) as any[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands])

  useEffect(() => {
    if (brand) {
      setBrandsIds([brand])
      setBrands(brands.filter(f => f.id === brand) as any[])
    }

    if (category) {
      setLastClickedId(category);
      setExpandedCategories([category]);
      setSelectedCategories([category]);
    }

    if (!category)
      load(query ?? "");

    if ((query ?? "") !== "" || (brand ?? "") !== "" || (category ?? "") !== "") {
      scroll();
    }

    setPage("ShoppingCatalogue", window.location.pathname);

    return () => {
      dispose();
      dispose_search();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, navigate, dispose, setPage, dispose_search]);

  const get_brands_text = () => {
    if ((selectedBrands?.length ?? 0) === 0) {
      return "Any"
    }
    if ((selectedBrands?.length ?? 0) >= 3) {
      return `${selectedBrands?.length} items`
    }
    else {
      return brands?.filter(f => selectedBrands?.includes(f.id)).map(x => x.title).join(", ")
    }
  }

  const which_brands = () => {
    if (searchText === "" && (selectedCategories?.length ?? 0) > 0) {
      var all_nested_brands = smartCategories.filter(y => selectedCategories?.includes(y.id)).map(f => f.brands).flatMap(x => x);
      var final_brands = Array.from(new Set(all_nested_brands))

      return brands.filter(f => final_brands.includes(f.id))
    }
    if (searchText !== "") {
      return brands.filter(f => available_brands.includes(f.id))
    }

    return [];
  }

  return (
    <div>
      {!isMobile && <MainBanner
        imageUrl="/aboutAssets/ct_banner.jpg"
        onSubmit={(data: any) => {
          scroll();
          search(data.searchTerm, selectedBrands, selectedCategories);
        }}
        scroll={scroll}
      />}
      <Fragment>
        <Divider hidden style={isMobile ? { padding: "0", margin: "0" } : undefined} />

        <Ref innerRef={stickyRef}>
          <Grid columns={isMobile ? 1 : 2}>
            {!isMobile && !isTablet &&
              <Grid.Column width={3}>
                <Sticky offset={80} context={stickyRef}>
                  {smartCategories.length > 0 && <CategoryBar />}
                </Sticky>
              </Grid.Column>}
            <Grid.Column className="productsContainer"
              width={(isMobile || isTablet) ? 16 : 13}
              style={isMobile ? { paddingTop: "15px" } : { paddingTop: "0px" }}
            >
              {/* <div>
                <AdvertPanel
                  items={[
                    {
                      imageUrl: "/christmas/ads/bg_1.png",
                      mobileImageUrl: "/christmas/ads/mob_1.png",
                      filter: {
                        type: "category",
                        filter: "66003dc9a40f12c32771c4f1"
                      }
                    },
                    {
                      imageUrl: "/christmas/ads/bg_2.png",
                      mobileImageUrl: "/christmas/ads/mob_2.png",
                      filter: {
                        type: "category",
                        filter: "67422d26d2e546296bc22938"
                      }
                    }
                  ]}
                  onClick={(filter: IAdvertFilter) => {
                    switch (filter.type) {
                      case "category":
                        setLastClickedId(filter.filter);
                        setExpandedCategories([filter.filter]);
                        setSelectedCategories([filter.filter]);
                        search("", [], [filter.filter], [], [])
                        break;
                      case "brand":
                        break;
                      case "text":
                        break;
                      default:
                        break;
                    }
                  }} />
              </div> */}
              <Sticky active={!isMobile} offset={45} context={stickyRef}>
                <div className="fxDisplay fxFixed fxJustifyBetween" style={{ paddingTop: "10px", backgroundColor: "white", marginBottom: "10px" }}>
                  <div className={`fxDisplay fxFixed fxStrech`} style={isMobile ? { maxWidth: "45%" } : {}}>
                    {isTablet &&
                      <div style={{ marginLeft: "10px" }}>
                        <Button
                          className="ct-green-button-active"
                          icon="sliders"
                          content={"Filter"}
                          onClick={() => {
                            setSidebarOpen(!sidebarOpen);
                            scroll()
                          }} />
                      </div>}
                    <div className="fxWrap">
                      <LabelGroup style={{ paddingLeft: "14px", paddingTop: "4px" }} >
                        {(searchText?.trim() ?? "") !== "" &&
                          <Label size="large" className="ct-green-button-active" style={{ borderRadius: "15px" }}>
                            {searchText}
                            <Icon name='close' onClick={() => {
                              scroll();
                              search(undefined, selectedBrands, selectedCategories, undefined, selectedBrands_obj)
                            }}
                            />
                          </Label>}
                        {selectedCategories?.map((x) =>
                          <Label key={`${x}_cat`} size="large" className="ct-green-button-active" style={{ borderRadius: "15px" }}>
                            {smartCategories.filter(f => f.id === x)[0]?.description}
                            <Icon name='close' onClick={() => { removeCategory(x) }} />
                          </Label>
                        )}
                        {selectedBrands?.map((x) =>
                          <Label key={`${x}_brand`} size="large" className="ct-green-button-active" style={{ borderRadius: "15px" }}>
                            {selectedBrands_obj.filter(f => f.id === x)[0]?.title}
                            <Icon name='close' onClick={() => { removeBrand(x) }} />
                          </Label>
                        )}
                      </LabelGroup>
                    </div>
                  </div>
                  <div className="fxFixed fxDisplay" style={isMobile ? { marginRight: "7px", display: "block" } : { marginRight: "7px" }}>
                    {which_brands().length > 1 && ((selectedCategories?.length ?? 0) > 0 || searchText !== "") &&
                      <div className="fxFixed fxDisplay" >
                        < div className="catalogue-sort-by-holder">Brands |</div>
                        <Popup
                          on="click"
                          closeOnTriggerClick
                          closeOnDocumentClick
                          closeOnPortalMouseLeave
                          closeOnTriggerBlur
                          closeOnTriggerMouseLeave
                          closeOnEscape
                          position="bottom center"
                          trigger={
                            <div className="fxFixed fxDisplay">
                              <div className="fxStrech brand-sort-by-selected">
                                {get_brands_text()}
                              </div>
                              <Icon name="angle down" />
                            </div>}
                          content={
                            <List size="medium" className="brands-search-list">
                              {which_brands()
                                ?.slice()
                                .sort((a, b) => a.title > b.title ? 1 : -1)
                                .map((f, i) => (
                                  <List.Item
                                    key={f.id}
                                    onClick={() => {
                                      var final_brands = [];
                                      if (selectedBrands?.includes(f.id)) {
                                        final_brands = selectedBrands_obj?.filter(x => x.id !== f.id);
                                      }
                                      else {
                                        final_brands = selectedBrands_obj ?? [];
                                        final_brands.push(f as Brand);
                                      }
                                      search(searchText, final_brands?.map(x => x.id) ?? [], selectedCategories, undefined, final_brands);
                                    }}
                                    className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                                  >
                                    <div className={`fxDisplay fxFixed ${(selectedBrands)?.includes(f.id) ? "ct-green-text-dark" : ""}`}  >
                                      <div style={{ width: "18px", textAlign: "start" }}>{(selectedBrands)?.includes(f.id) && <Icon name={"check"} />}</div>
                                      <div style={{ marginLeft: "5px" }}>{f.title}</div>
                                    </div>
                                  </List.Item>
                                ))}
                            </List>
                          }
                        />
                      </div>}
                    <div className="fxFixed fxDisplay" >
                      <div className="catalogue-sort-by-holder">Sort by |</div>
                      <Popup
                        on="click"
                        closeOnTriggerClick
                        closeOnDocumentClick
                        closeOnPortalMouseLeave
                        closeOnTriggerBlur
                        closeOnTriggerMouseLeave
                        closeOnEscape
                        position="bottom right"
                        trigger={
                          <div className="fxFixed fxDisplay">
                            <div className="fxStrech catalogue-sort-by-selected">
                              {SortByOptions.filter(f => f.key === sortBy)[0].text}
                            </div>
                            <Icon name="angle down" />
                          </div>}
                        content={
                          <List size="medium">
                            {SortByOptions.map((f, i) => (
                              <List.Item
                                key={f.value}
                                onClick={() => { setSortBy(f.key) }}
                                className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                              >
                                <div className={`fxDisplay fxFixed ${sortBy === f.key ? "ct-green-text-dark" : ""}`}  >
                                  <div style={{ width: "18px", textAlign: "start" }}>{sortBy === f.key && <Icon name={"check"} />}</div>
                                  <div style={{ marginLeft: "5px" }}>{f.text}</div>
                                </div>
                              </List.Item>
                            ))}
                          </List>
                        }
                      />
                    </div>
                  </div>
                </div>
              </Sticky>

              <SidebarPushable>

                <Sidebar
                  as={Segment}
                  style={isMobile ?
                    { position: "absolute", minHeight: "100vh", paddingTop: "24px" }
                    : { position: "absolute", minHeight: "100vh" }}
                  animation='overlay'
                  direction="left"
                  // icon='labeled'
                  onHide={() => setSidebarOpen(false)}
                  visible={sidebarOpen}
                  width='wide'
                >
                  {smartCategories.length > 0 && <CategoryBar />}
                </Sidebar>
                <SidebarPusher>
                  {(suggestions?.length ?? 0) > 0 && <div style={isMobile ? { fontSize: "16px", textAlign: "center" } : { fontSize: "16px" }}>
                    Including results for <span className="clickable" style={{ fontWeight: "bold", fontStyle: "italic" }} onClick={() => suggestion_search("y")}>"{suggestions?.join(",")}"</span>. Search only for <span className="clickable" style={{ fontWeight: "bold", fontStyle: "italic" }} onClick={() => suggestion_search("n")} > "{searchText}"</span> ?
                  </div>}
                  <div className="catalogueProductsContainer" style={{ minHeight: '90vh' }}>
                    <InfiniteScroll
                      style={{ overflow: "hidden" }}
                      dataLength={products.length} //This is important field to render the next data
                      next={() => load()}
                      hasMore={hasMore}
                      endMessage={products.length > 0 || loading ? (<p />) : (<NoResults />)}
                      loader={
                        <Segment basic>
                          <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                          </Dimmer>
                        </Segment>
                      }
                    >
                      <Card.Group
                        centered
                        className="productPage"
                        itemsPerRow={isMobile ? 2 : undefined}
                        style={{ padding: "5px" }}
                      >
                        {products
                          .filter(
                            (f) => (f.price ?? 0) > 0)
                          .map(
                            (x, i) => (
                              <CatalogueCard key={`${x.id} _${i}`} product={x} />
                            )
                          )}
                        {/* {!hasMore && <InlineAdd key={"last_ad"} />} */}
                      </Card.Group>
                    </InfiniteScroll>
                  </div>
                </SidebarPusher>
              </SidebarPushable>

            </Grid.Column>
          </Grid>
        </Ref>


      </Fragment>
    </div >
  );
};

export default observer(ShoppingCatalogue);
