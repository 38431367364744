import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Button, Divider, Grid, Header, Icon, Image, Label, Message, Popup, Segment, Table, TableCell } from 'semantic-ui-react';
import { formatRelative } from 'date-fns';
import { getImageUrlPlain } from '../../../helpers/productImage';
import { getMarketImageUrl } from '../../../helpers/supermarketImage';
import { IAssociationForScore } from '../../../interfaces/association';
import { IProduct } from '../../../interfaces/product';
import Associations from '../../associations/Associations';
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes';
import { currencyFormat } from '../../products/functions/productHelper';
import NewAssociationWidget from './NewAssociationWidget';
import Highlighter from "react-highlight-words";

const OneByOne = () => {
    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;
    const { closeConfirmationalModal, openConfirmationModal } =
        context.confirmationModalStore;
    const { approvePossibleGroupRelation } = context.orphanStore;
    const {
        loadNext,
        dispose,
        association,
        loading,
        clickRow,
        approveAssociation,
        toggleExpanded,
        expandedIds,
        mergeAssociation,
        removeProductRelation,
        deletePossibleAssociation,
        toggleView,
        existingGroupsView,
        hiddenIds,
        setHiddenIds
    } = context.possibleAssociationsV2Store;

    useEffect(() => {
        loadNext();

        return () => {
            (window as any).groupForm = null;
        };
    }, [loadNext, dispose]);

    const header = <div className="ui tiny compact text labeled icon menu">
        <span
            title="Merge to group"
            className="item"
            onClick={() => toggleView()}
        >
            <i
                aria-hidden="true"
                className="purple large fitted icon paperclip"
            ></i>
            Merge to group
        </span>
        <span
            title="Remove Product"
            className="item"
            onClick={() => {
                openConfirmationModal(
                    "Confirm product info deletion",
                    "Are you sure you want to delete this product?",
                    () =>
                        deletePossibleAssociation().then(() =>
                            closeConfirmationalModal()
                        ),
                    "Cancel",
                    "Delete",
                    ButtonTypes.None,
                    ButtonTypes.Negative
                );
            }}
        >
            <i
                aria-hidden="true"
                className="red large fitted icon trash alternate"
            ></i>
            Delete
        </span>
    </div>;

    return (
        <Segment.Group horizontal={true} style={{ maxHeight: "90vh", overflowY: "auto" }}>
            <Segment style={{ maxWidth: "50%", minWidth: "50%" }}>
                {!loading && !association && (
                    <Message info content="No pending possible associations" />
                )}
                {association && (
                    <Fragment>
                        <NewAssociationWidget
                            loading={loading}
                            approveAssociation={approveAssociation}
                            products={association.products}
                        />
                    </Fragment>
                )}
                <Table celled>
                    <Table.Body>
                        {association &&
                            association.products?.map((f: IProduct, i: number) => (
                                <Table.Row key={f.id}>
                                    <Table.Cell>
                                        <Header as="h4" image>
                                            <Popup
                                                trigger={
                                                    <Image
                                                        src={getImageUrlPlain(f.marketId, f.imageUrl)}
                                                        alt="Product Logo"
                                                        rounded
                                                        size="mini"
                                                        onClick={() =>
                                                            clickRow(
                                                                undefined,
                                                                getImageUrlPlain(f.marketId, f.imageUrl)
                                                            )
                                                        }
                                                    />
                                                }
                                                content={
                                                    <Image
                                                        src={
                                                            getImageUrlPlain(f?.marketId, f?.imageUrl) ??
                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                        }
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src =
                                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                        }}
                                                        alt="Product Logo"
                                                        rounded
                                                    />
                                                }
                                            />
                                            <Header.Content
                                                onClick={() => clickRow(f.itemDesc, undefined)}
                                            >
                                                {f.itemDesc}
                                            </Header.Content>
                                            <div style={{ color: "#777" }} className='fxDisplay fxFixed fxAlignCenter'>
                                                <div>
                                                    <Image
                                                        alt="Product Logo"
                                                        size="mini"
                                                        src={getMarketImageUrl(
                                                            markets?.filter((z) => z.type_id === f.marketId)[0]
                                                        )}
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "5px" }}>{
                                                    markets?.filter((z) => z.type_id === f.marketId)[0]
                                                        .title
                                                }</div>
                                            </div>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatRelative(
                                            new Date(f.lastUpdated * 1000),
                                            new Date(),
                                            {}
                                        )}
                                        {f.discontinued && (
                                            <p>
                                                <Label color="red" basic content="Discontinued" />
                                            </p>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Fragment>
                                            <p>
                                                <b>SKU :</b> {f.sku ?? "-"}
                                            </p>
                                            <p>
                                                <b>Internal Id :</b> {f.internalId}
                                            </p>
                                        </Fragment>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div
                                            style={{
                                                marginBottom: "0.5em",
                                                fontSize: "17px",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            <Icon name="euro" />
                                            {currencyFormat(f.currentPrice, false)}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            circular
                                            color="red"
                                            icon="minus"
                                            size="tiny"
                                            onClick={() => removeProductRelation(f.id)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Segment>

            {existingGroupsView &&
                association?.orphanProducts &&
                (association?.orphanProducts.length ?? 0) > 0 && (
                    <div>
                        <Header content="Orphan Products" />
                        <Divider />
                        <Segment>
                            <Table basic="very" celled>
                                <Table.Body>
                                    {association?.orphanProducts &&
                                        association.orphanProducts.map((f) => (
                                            <Table.Row key={f.id}>
                                                <Table.Cell>
                                                    <Header as="h4" image>
                                                        <Popup
                                                            trigger={
                                                                <Image
                                                                    src={getImageUrlPlain(
                                                                        f.marketId,
                                                                        f.imageUrl
                                                                    )}
                                                                    alt="Product Logo"
                                                                    rounded
                                                                    size="mini"
                                                                />
                                                            }
                                                            content={
                                                                <Image
                                                                    src={
                                                                        getImageUrlPlain(
                                                                            f?.marketId,
                                                                            f?.imageUrl
                                                                        ) ??
                                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                    }
                                                                    onError={({ currentTarget }: any) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src =
                                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                    }}
                                                                    alt="Product Logo"
                                                                    rounded
                                                                />
                                                            }
                                                        />
                                                        <Header.Content>{f.itemDesc}</Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {formatRelative(
                                                        new Date(f.lastUpdated * 1000),
                                                        new Date(),
                                                        {}
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {f.discontinued && (
                                                        <p>
                                                            <Label
                                                                color="red"
                                                                basic
                                                                content="Discontinued"
                                                            />
                                                        </p>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Fragment>
                                                        <p>
                                                            <b>SKU :</b> {f.sku ?? "-"}
                                                        </p>
                                                        <p>
                                                            <b>Internal Id :</b> {f.internalId}
                                                        </p>
                                                    </Fragment>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Fragment>
                                                        <Image
                                                            alt="Product Logo"
                                                            size="tiny"
                                                            src={getMarketImageUrl(
                                                                markets?.filter(
                                                                    (z) => z.type_id === f.marketId
                                                                )[0]
                                                            )}
                                                        />
                                                        <p>
                                                            {
                                                                markets?.filter(
                                                                    (z) => z.type_id === f.marketId
                                                                )[0].title
                                                            }
                                                        </p>
                                                    </Fragment>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div
                                                        style={{
                                                            marginBottom: "0.5em",
                                                            fontSize: "17px",
                                                            fontWeight: "bolder",
                                                        }}
                                                    >
                                                        <Icon name="euro" />
                                                        {currencyFormat(f.currentPrice, false)}
                                                    </div>
                                                </Table.Cell>
                                                <TableCell>
                                                    <Button
                                                        circular
                                                        color="green"
                                                        icon="add"
                                                        size="tiny"
                                                        onClick={() =>
                                                            approvePossibleGroupRelation(
                                                                association.id,
                                                                f.id
                                                            ).then(() => loadNext(association.id))
                                                        }
                                                    />
                                                </TableCell>
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </div>
                )}

            {(!existingGroupsView || association?.possibleGroups?.length === 0) && (
                <Segment>
                    {header}
                    <Associations mode="group_select" />
                </Segment>
            )}

            {existingGroupsView &&
                association &&
                (association.possibleGroups?.length ?? 0) > 0 && (
                    <Segment style={{ maxHeight: "90vh", overflowY: "auto" }}>
                        {header}
                        {association.possibleGroups
                            ?.slice()
                            .sort((a: IAssociationForScore, b: IAssociationForScore) =>
                                a.score < b.score ? 1 : -1
                            )
                            .map((x: IAssociationForScore) => (
                                <Segment key={x.id} style={{
                                    maxHeight: "450px", overflow: "auto"
                                }}>
                                    <div className='fxFixed fxDisplay'>
                                        <div className='fxStretch'>
                                            <div>
                                                Title : <b>
                                                    <Highlighter
                                                        caseSensitive={false}
                                                        highlightStyle={{
                                                            background: "#FFD465",
                                                        }}
                                                        searchWords={association.products[0].itemDesc.split(" ") ?? []}
                                                        autoEscape={true}
                                                        textToHighlight={x.title}
                                                    />
                                                </b>
                                            </div>
                                            <div>
                                                Score : <b>{x.score}</b>
                                            </div>
                                            <div>
                                                Sku : 
                                                {/* {Array.from(new Set(x.products.filter(f => f.sku).map((z) => z.sku))).join(",")} */}
                                                <b>
                                                    <Highlighter
                                                        caseSensitive={false}
                                                        highlightStyle={{
                                                            background: "#FFD465",
                                                        }}
                                                        searchWords={association?.products.map(x => x.sku) ?? 0}
                                                        autoEscape={true}
                                                        textToHighlight={Array.from(new Set(x.products.filter(f => f.sku).map((z) => z.sku))).join(",")}
                                                    />
                                                </b>
                                            </div>
                                            <div>
                                                Active : <b
                                                    style={{ color: x.products.filter(f => (f.discontinued ?? false) === false).length > 0 ? 'green' : 'red' }}>
                                                    {x.products.filter(f => (f.discontinued ?? false) === false).length > 0 ? "Yes" : "No"}
                                                </b>
                                            </div>
                                            <div>
                                                Price : <b
                                                    style={{ color: x.products.slice().sort((a, b) => a.currentPrice > b.currentPrice ? 1 : -1)[0].currentPrice <= association.products[0].currentPrice ? "green" : "red" }}
                                                >
                                                    {x.products.slice().sort((a, b) => a.currentPrice > b.currentPrice ? 1 : -1)[0].currentPrice}</b> -
                                                <b
                                                    style={{ color: x.products.slice().sort((a, b) => a.currentPrice > b.currentPrice ? -1 : 1)[0].currentPrice >= association.products[0].currentPrice ? "green" : "red" }}
                                                > {x.products.slice().sort((a, b) => a.currentPrice > b.currentPrice ? -1 : 1)[0].currentPrice}</b>
                                            </div>
                                        </div>
                                        <div className="ui green tiny compact text labeled icon menu">
                                            <span
                                                title="Approve"
                                                className="item"
                                                onClick={() => {
                                                    mergeAssociation(x.id);
                                                }}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="green chain large fitted icon"
                                                ></i>
                                                Approve
                                            </span>
                                            <span
                                                title="Hide Group"
                                                className="item"
                                                onClick={() => {
                                                    setHiddenIds([...hiddenIds, x.id])
                                                }}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="red large fitted icon trash alternate"
                                                ></i>
                                                Hide Group
                                            </span>

                                            <span
                                                className="item"
                                                title="Expand/Compress"
                                                onClick={() => toggleExpanded(x.id)}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className={`grey large fitted icon ${expandedIds.findIndex(
                                                        (z: string) => z === x.id
                                                    ) > -1
                                                        ? "compress"
                                                        : "expand"
                                                        }`}
                                                ></i>
                                                {expandedIds.findIndex(
                                                    (z: string) => z === x.id
                                                ) > -1
                                                    ? "Compress"
                                                    : "Expand"}
                                            </span>
                                        </div>
                                    </div>
                                    {(expandedIds.findIndex(
                                        (z: string) => z === x.id
                                    ) > -1) && <Table celled style={{ maxHeight: "50px", overflow: "auto" }}>
                                            <Table.Body>
                                                {x.products &&
                                                    x.products.map(
                                                        (f, i) =>
                                                            (i < 1 ||
                                                                expandedIds.findIndex(
                                                                    (z: string) => z === x.id
                                                                ) > -1) && (
                                                                <Table.Row key={f.id}>
                                                                    <Table.Cell>
                                                                        <Header as="h4" image>
                                                                            <Popup
                                                                                trigger={
                                                                                    <Image
                                                                                        src={getImageUrlPlain(
                                                                                            f.marketId,
                                                                                            f.imageUrl
                                                                                        )}
                                                                                        alt="Product Logo"
                                                                                        rounded
                                                                                        size="mini"
                                                                                    />
                                                                                }
                                                                                content={
                                                                                    <Image
                                                                                        src={
                                                                                            getImageUrlPlain(
                                                                                                f?.marketId,
                                                                                                f?.imageUrl
                                                                                            ) ??
                                                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                                        }
                                                                                        onError={({
                                                                                            currentTarget,
                                                                                        }: any) => {
                                                                                            currentTarget.onerror = null;
                                                                                            currentTarget.src =
                                                                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                                        }}
                                                                                        alt="Product Logo"
                                                                                        rounded
                                                                                    />
                                                                                }
                                                                            />
                                                                            <Header.Content>
                                                                                <Highlighter
                                                                                    caseSensitive={false}
                                                                                    highlightStyle={{
                                                                                        background: "#FFD465",
                                                                                    }}
                                                                                    searchWords={association.products[0].itemDesc.split(" ") ?? []}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={f.itemDesc}
                                                                                />
                                                                            </Header.Content>
                                                                        </Header>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {formatRelative(
                                                                            new Date(f.lastUpdated * 1000),
                                                                            new Date(),
                                                                            {}
                                                                        )}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {f.discontinued && (
                                                                            <p>
                                                                                <Label
                                                                                    color="red"
                                                                                    basic
                                                                                    content="Discontinued"
                                                                                />
                                                                            </p>
                                                                        )}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Fragment>
                                                                            <p>
                                                                                <b>SKU :</b> 
                                                                                <Highlighter
                                                                                    caseSensitive={false}
                                                                                    highlightStyle={{
                                                                                        background: "#FFD465",
                                                                                    }}
                                                                                    searchWords={association?.products.map(x => x.sku) ?? 0}
                                                                                    autoEscape={true}
                                                                                    textToHighlight={f.sku ?? "-"}
                                                                                />
                                                                            </p>
                                                                            <p>
                                                                                <b>Internal Id :</b> {f.internalId}
                                                                            </p>
                                                                        </Fragment>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Fragment>
                                                                            <Image
                                                                                alt="Product Logo"
                                                                                size="tiny"
                                                                                src={getMarketImageUrl(
                                                                                    markets?.filter(
                                                                                        (z) => z.type_id === f.marketId
                                                                                    )[0]
                                                                                )}
                                                                            />
                                                                            <p>
                                                                                {
                                                                                    markets?.filter(
                                                                                        (z) => z.type_id === f.marketId
                                                                                    )[0].title
                                                                                }
                                                                            </p>
                                                                        </Fragment>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: "0.5em",
                                                                                fontSize: "17px",
                                                                                fontWeight: "bolder",
                                                                            }}
                                                                        >
                                                                            <Icon name="euro" />
                                                                            {currencyFormat(f.currentPrice, false)}
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                    )}
                                            </Table.Body>
                                        </Table>}
                                </Segment>
                            ))}
                    </Segment>
                )
            }
        </Segment.Group >
    )
}

export default observer(OneByOne)